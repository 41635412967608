
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { Subject } from "rxjs";
import Vue from "vue";
import LanguagesService from "@/services/LanguagesService";
import { DataEmitterService } from "@/services/DataEmiterService";

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) );

    return {
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      textsSnippet: snippets ?? {},
      textsList: [],
      textsPaginatedListSet: {},
      searchedtextsPaginatedListSet: {},
      searchedTextsList: [],
      paginatedTextsList: [],
      paginatedSearchedTextsList: [],
      spacesList: [],
      organizationsList: [],
      organizationsNamesList: [],
      loadedPages: [],
      searchedData: [],
      searchedDataOriginal: '',
      selectedOrganization: '',
      currentManagerOrganization: '',
      searchKey: '',
      searchField: '',
      deletedItem: '',
      dialog: false,
      dialogDelete: false,
      dialogUpgrade: false,
      submitted: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        key: '',
        value: '',
        language: '',
      },
      defaultItem: {
        id: '',
        key: '',
        value: '',
        language: '',
      },
      sortBy: 'id',
      editedPrefix: '',
      searchResults: '',
      search$: new Subject(),
      sortDesc: true,
      errorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldText: {},
      addressesDataList: [],
      itemsPerPageOptions: [ 0 ],
      textData: { texts: [], count: Number },
      spaceData: [],
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      canOverwrite: true,
      languages: [],
      language: '',
      defaultLanguageKey: '',
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.textsSnippet.add_text : this.textsSnippet.edit_text
    },

    headers() {
      return [
        { text: this.textsSnippet.id, value: 'id', width: '15%', sortable: true },
        { text: this.textsSnippet.key, value: 'key', width: '20%', sortable: true },
        {
          text: this.textsSnippet.language,
          value: 'language',
          width: '10%',
          sortable: true
        },
        {
          text: this.textsSnippet.text,
          value: 'value',
          width: '45%',
          sortable: true
        },
        { text: this.textsSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.search$.next( '' );
        this.searchField = ''
        this.searchedData = [];
      }
    },

  },
  beforeRouteLeave( to, from, next ) {
    this.saveTextsDataToSessionStorage();
    // StorageService.deleteTextFromStorage();
    next()
  },

  async mounted() {
    this.searchAction();

    window.addEventListener( 'beforeunload', this.saveTextsDataToSessionStorage );
    let storageTexts = null//this.getTextsDataFromSessionStorage();
    if ( storageTexts?.data ) {
      this.textData = storageTexts;
      this.loadedPages.push( +storageTexts.currentPage );
      await this.getTextsPaginated();
    } else {
      await this.getTextsApi();
    }

    this.languages = LanguagesService.languages;
    if ( !this.languages ) {
      new LanguagesService().setLanguages().then( ( languages ) => {
        this.languages = languages.map( i => i.key )
        this.defaultLanguageKey = this.languages[0];
      } );
    } else {
      this.languages = this.languages.map( i => i.key )
      this.defaultLanguageKey = this.languages[0];
    }
    this.loading = false;

  },

  methods: {
    customSort( items ) {
      return items
    },

    async sortTable() {
      await Promise.resolve();
      this.changePage( this.currentPage ?? 1 );
    },

    getSortQuery( comesFirst = false ) {
      if ( this.sortBy ) {
        return `${ comesFirst ? '?' : '&' }sortBy=${ this.sortBy }&direction=${ this.sortDesc ? 'DESC' : 'ASC' }`;
      }

      return '';
    },

    saveTextsDataToSessionStorage() {
      //TODO: remove
      return
      if ( this.canOverwrite ) {
        sessionStorage.setItem( 'texts_paginated_list', JSON.stringify( this.textData ) )
        sessionStorage.setItem( 'texts_searched_text', JSON.stringify( this.searchField ) )
        sessionStorage.setItem( 'texts_searched_list', JSON.stringify( this.searchedDataOriginal ) )
        sessionStorage.setItem( 'texts_paginated_list_save_date', Date.now().toString() )
      }
    },

    getTextsDataFromSessionStorage() {
      let textsData = JSON.parse( sessionStorage.getItem( 'texts_paginated_list' ) );
      this.searchKey = JSON.parse( sessionStorage.getItem( 'texts_searched_text' ) );
      if ( this.searchKey ) {
        textsData = {};
      }
      this.currentPage = textsData?.currentPage ? +textsData.currentPage : 1;
      return textsData;
    },


    openDetails( text ) {
      if ( text && text.id ) {
        this.$router.push( `text-details/${ text.id }` )
      }
    },

    searchAction() {
      this.search$ = new Subject();

      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getTextsPaginated();
          return
        }

        let data = await this.searchText( term );
        await this.getSearchedTextsPaginated( data );
      } )
    },

    searchText( term ) {
      return ApiRequestService.getRequest( `api/admin-language/text?searchText=${ term }${ this.getSortQuery() }` );
    },

    async changePage( page ) {
      this.textsPaginatedListSet = {};
      this.searchedtextsPaginatedListSet = {};
      this.paginatedSearchedTextsList.length = 0;
      this.paginatedTextsList.length = 0;
      this.textsList.length = 0;
      this.currentPage = page;

      if ( this.searchField ) {
        this.searchedDataOriginal = await ApiRequestService.getRequest( `api/admin-language/text?page=${ page }&searchText=${ this.searchField }${ this.getSortQuery() }` );


        if ( this.searchedDataOriginal?.data?.length ) {
          await this.setSearchedTextsToTextsList( this.searchedDataOriginal.data );
        }
        return;
      }

      this.textData = await ApiRequestService.getRequest( `api/admin-language/text?page=${ page }${ this.getSortQuery() }` );
      this.loadedPages = [ this.currentPage ];

      if ( this.textData?.data?.length ) {
        await this.setTextsToTextsList( this.textData.data );
      }
    },

    async getTextsApi() {
      this.textData = await ApiRequestService.getRequest( `api/admin-language/text${ this.getSortQuery( true ) }` );
      await this.getTextsPaginated();
    },

    async getTextsPaginated() {
      this.pagesCount = this.textData.totalPages
      if ( this.pagesCount ) {
        await this.setTextsToTextsList( this.textData.data );
      }
    },

    async getSearchedTextsPaginated( data ) {
      this.pagesCount = Math.ceil( data.totalElements / 10 )

      this.searchedTextsList = [];
      this.paginatedSearchedTextsList.length = 0;
      this.searchedtextsPaginatedListSet = {};
      await this.setSearchedTextsToTextsList( data.data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedTextsList )
    },

    async setTextsToTextsList( textData ) {
      textData.forEach( ( text, index ) => {
        let existsIndex = this.textsList.findIndex( item => item.id === text?.id );
        let textData = {
          id: text?.id,
          key: text.key,
          value: text.value,
          language: text.language,
        };
        if ( existsIndex !== -1 ) {
          Vue.set( this.textsList, existsIndex, textData )

          let values = this.textsPaginatedListSet[this.currentPage] ? this.textsPaginatedListSet[this.currentPage].values() : [];
          let textsPaginatedListSetClone = [ ...values ];
          textsPaginatedListSetClone.forEach( item => {
            if ( item?.id === textData?.id ) {
              this.textsPaginatedListSet[this.currentPage].delete( item );
              this.textsPaginatedListSet[this.currentPage].add( textData );
            }
          } );
          return;
        }
        Vue.set( this.textsList, this.textsList.length, textData )

        if ( !this.textsPaginatedListSet[this.currentPage] ) {
          this.textsPaginatedListSet[this.currentPage] = new Set();
        }
        if ( this.textsPaginatedListSet[this.currentPage].size < 10 ) {
          this.textsPaginatedListSet[this.currentPage].add( textData );
        } else {
          this.textsPaginatedListSet[this.currentPage].delete( [ ...this.textsPaginatedListSet[this.currentPage] ][9] );
          this.textsPaginatedListSet[this.currentPage].add( textData );
        }
      } )
      if ( this.textsPaginatedListSet[this.currentPage] ) {

        if(this.deletedItem[0]){
          this.textsPaginatedListSet[this.currentPage] = new Set([...this.textsPaginatedListSet[this.currentPage]].filter(item => item.id !== this.deletedItem[0].id))
        }
        this.paginatedTextsList = this.getObjClone( [ ...this.textsPaginatedListSet[this.currentPage] ] )
      }
    },

    async setSearchedTextsToTextsList( data ) {
      data.forEach( ( text, index ) => {
        let existsIndex = this.paginatedSearchedTextsList.findIndex( item => item.id === text?.id );
        let textData = {
          id: text?.id,
          language: text?.language,
          key: text?.key,
          value: text?.value
        };

        if ( existsIndex !== -1 ) {
          let searchedtextsPaginatedListSetClone = this.getObjClone( this.searchedtextsPaginatedListSet[this.currentPage] )

          searchedtextsPaginatedListSetClone.forEach( ( item, index ) => {
            if ( item?.id === textData?.id ) {
              this.searchedtextsPaginatedListSet[this.currentPage][index] = textData
            }
          } );
          return;
        }

        if ( !this.searchedtextsPaginatedListSet[this.currentPage] ) {
          this.searchedtextsPaginatedListSet[this.currentPage] = [];
        }
        if ( this.searchedtextsPaginatedListSet[this.currentPage].length < 10 ) {
          this.searchedtextsPaginatedListSet[this.currentPage].push( textData );
        }
      } )

      if ( this.searchedtextsPaginatedListSet[this.currentPage] ) {
        this.paginatedSearchedTextsList = this.getObjClone( this.searchedtextsPaginatedListSet[this.currentPage] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addTextToTextList( data ) {
      let newTextData = {
        id: data?.id,
        language: data?.language,
        key: data?.key,
        value: data?.value,
      };

      if ( this.searchField ) {
        let existsIndex = this.paginatedSearchedTextsList?.findIndex( text => text.id === newTextData.id );
        if ( existsIndex !== -1 ) {
          this.paginatedSearchedTextsList[existsIndex] = newTextData;
        } else {
          this.paginatedSearchedTextsList.unshift( newTextData );
          this.searchedDataOriginal.data.totalElements++;
        }

        this.setSearchedTextsToTextsList( this.paginatedSearchedTextsList )

      } else {
        let existsIndex = this.textData?.data?.findIndex( text => text.id === newTextData.id );
        if ( existsIndex !== -1 ) {
          this.textData.data[existsIndex] = newTextData;
        } else {
          this.textData.data.unshift( newTextData );
          this.textData.totalElements++;
        }
        this.setTextsToTextsList( this.textData.data )
      }
    },

    getChangedData( oldText ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldText[key] === this.editedItem[key] ) {
          continue
        }


        dataFormUpdate[key] = this.editedItem[key]
      }
      return dataFormUpdate
    },

    dataIsValid() {
      const keyIsValid = this.textIsValid( this.editedItem?.key?.trim() )
      const isNotValid = []

      if ( keyIsValid !== true ) {
        isNotValid.push( keyIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    textIsValid( v ) {
      return ( v && v.length > 0 ) || this.textsSnippet.required
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedTextsList : this.paginatedTextsList;
      this.editedIndex = list.findIndex( text => text.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },


    deleteItemConfirm() {
      let paginatedList = this.searchField ? this.paginatedSearchedTextsList : this.paginatedTextsList;
      let list = this.searchField ? this.searchedTextsList : this.textsList;
      paginatedList.splice( this.editedIndex, 1 )
      list.splice( this.editedIndex, 1 )
      this.deletedItem = this.textData.data[this.editedIndex];
      ApiRequestService.deleteRequest( `api/admin-language/text/${ this.editedItem.id }` ).then()

      this.closeDelete()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) )
    },

    close() {
      this.dialog = false
      this.submitted = false
      this.errorKey = ''

      /**
       * if action is edit, set to form old data
       */
      let data
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldText )
      } else {
        data = this.getObjClone( this.defaultItem )
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue )
          }
        } else {
          this.$set( this.editedItem, key, value )
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeDelete() {
      this.dialogDelete = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    setNewTextToTextsList( textData ) {
      this.addTextToTextList( textData )
      this.close()
    },

    async saveText() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      if ( !this.editedItem.language ) {
        this.editedItem.language = this.defaultLanguageKey;
      }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }

      const textData = await ApiRequestService.postRequest( 'api/admin-language/text', dataToCreate );
      if ( textData?.errors ) {
        this.errorKey = textData?.errors;
        return;
      } else if ( textData?.errorMessage ) {
        this.errorKey = textData?.errorMessage;
        return;
      }
      if ( !textData ) {
        return;
      }
      this.setNewTextToTextsList( textData )

    },

    save() {
      this.submitted = true

      this.saveText()
    },

    selectLanguage( language ) {
      this.editedItem.language = language;
    },
  },
}

